<script>
import { fandomBasicMixin, fandomExtendedMixin, fandomAuthorizationMixin } from "src/modules/fandom_mixins_module.js";
import { mqLess } from 'src/modules/viewport_module';
import tileMixin from '../../modules/kenwood_swiss_tile_mixin.js';
import { get } from 'lodash';
import Intersect from 'vue-intersect';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, fandomAuthorizationMixin, tileMixin],
  components: { Intersect },
  data() {
    return {
      loadVideo: false
    }
  },
  mounted() {
  },
  methods: {
    startVideo(event) {
      if (this.content.background_video && this.toggleVideoCondition(event)) {
        this.loadVideo = true;

        // We await the reprint so that the video is presented on the page
        Vue.nextTick(() => { 
          const video = $(this.$refs.video)[0];
          video.play();
        });
      }
    },
    pauseVideo(event) {
      if (this.content.background_video && this.loadVideo && this.toggleVideoCondition(event)) {
        const video = $(this.$refs.video)[0];
        video.pause();
        video.currentTime = 0;

        this.loadVideo = false;
      }
    },
    toggleVideoCondition(event) {
      return (event == 'mouseenter' && !mqLess("sm")) ||
             (event == 'intersect' && mqLess("sm"))
    }
  },
  computed: {
    url() {
      return this.isAccessible ? get(this.content, ['url','url'], Fandom.getContentURL(this.content)) : 'javascript:void(0)';
    },
    subtitle() {
      return this.content?.subtitle ?? this.content?.description ?? false
    }
  }
}
</script>

<template>
  <intersect @enter="startVideo('intersect')" @leave="pauseVideo('intersect')">
    <div class="position-absolute-center" 
      v-easyadmin="easyadminId" 
      :class="contentType"
      @mouseenter="startVideo('mouseenter')"
      @mouseleave="pauseVideo('mouseenter')"
    >
      <a
        class="deco-none position-absolute-center cursor-pointer bg-transition"
        :target="getTarget(content.url)"
        :href="url"
        :style="theme"
        @click="lockedContentClickHandler($event)"
        tile-component
      >
        <div class="position-absolute-center bg-cover-center bg" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}"></div>
        <div class="position-absolute-center" v-if="loadVideo && content.background_video">
          <video ref="video" loop muted playsinline webkit-playsinline class="w-100 h-100">
            <source :src="content.background_video.url" type="video/mp4">
          </video>
        </div>
        
        <div v-if="!content.hide_gradient" class="position-absolute-center linear-gradient-y"></div>

        <div class="tile__content position-absolute-center p-3 p-lg-4 d-flex flex-column justify-content-between">
          <component 
            v-if="owner"
            :is="owner.url ? 'a' : 'div'" 
            :href="owner.url ? owner.url.url : null" 
            :target="getTarget(owner.url)" 
            class="owner position-absolute d-flex flex-row-reverse align-items-center pt-4 pr-4"
          >
            <div class="owner-avatar bg-cover-center d-flex justify-content-center align-items-center" :style="avatarStyle">
              <span v-if="!ownerAvatarUrl" class="text-white">{{ownerInitials}}</span>
            </div>
            <div class="owner-title d-flex flex-column align-items-end pr-3">
              <div class="owner-name text-uppercase">{{owner.first_name}} {{ owner.last_name }}</div>
              <div class="owner-name">{{owner.job_title }}</div>
            </div>
          </component>
          <div class="d-flex tile__category small text-uppercase">
            <span v-if="category" class="px-3 py-1" :style="categoryStyle">{{ category }}</span>
          </div>
          <div class="tile__text-section">
            <component v-if="content.title" class="title pb-2 pb-lg-3" :class="content.title_class" :is="content.title_tag || 'h3'" :style="{'color': content.text_color}">
              {{content.title}}
            </component>
            <component v-if="subtitle" :is="content.subtitle_tag || 'p'" class="subtitle" :class="content.subtitle_class" :style="{'color': `${content.text_color}`}" v-html="subtitle"></component>
          </div>
          <div class="tile__lock-icon position-absolute d-flex align-items-center justify-content-start" v-if="!isAccessible">
            <i class="far fa-lock-alt fa-lg p-4 text-white"></i>
          </div>
        </div>
      </a>
    </div>
  </intersect>
</template>

<style lang="scss" scoped>
[tile-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
  transition: all .25s;

  video {
    object-fit: cover;
  }

  &:not([href]):hover {
    color: var(--theme-color);
  }

  .tile__content {
    .owner {
      right: 0;
      top: 0;

      &-name {
        font-weight: 600;
        font-size: .75rem;
        max-height: 36px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-avatar {
        border-radius: 50%;
        border: 2px solid $white;
        width: 3.4rem;
        height: 3.4rem;
        background-color: $primary;
      }
    }

    .tile__text-section {
      @include media-breakpoint-down(md) {
        .title {
          font-size: 1.5rem;
        }

        .subtitle {
          font-size: $small-font-size;
        }
      }
    }
  }

  .tile__lock-icon {
    right: 0;
    width: 7rem;
    bottom: 2rem;
    background-color: $primary;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
  }

  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }
}
</style>



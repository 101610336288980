<script>
import { get } from "lodash";
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return { };
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    getTarget: Fandom.getTarget,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    columns(menuItem) {
      const columns = [];

      menuItem.menu.forEach((section, index) => {
        const pos = Math.floor(index / 2);

        if (!columns[pos]) {
          columns[pos] = [];
        }
        columns[pos].push(section);
      });

      return columns;
    },
    linkUrl(item) {
      const url = get(item, ["url", "url"], false);
      return !url || url == "#" ? "javascript:void(0)" : url;
    },
    isItemActive(item) {
      const re = new RegExp(`(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name);
    }
  },
  computed: {
    isMobile() {
      return globalState.viewport.sm || globalState.viewport.xs;
    },
    menu() {
      return this.isMobile ? (this.content.mobile_highlights || []) : this.content.menu;
    }
  },
};
</script>

<template>
  <div class="w-100 d-flex border-bottom border-gray-primary" navbar-component>
    <div class="hamburger d-md-none px-md-4 mx-md-3 border-right border-lg-right-none border-gray-primary" @click="$emit('toggle-sidebar')">
      <i class="fal fa-bars fa-lg"></i>
    </div>
    <div class="d-flex justify-content-center align-items-center w-100">
      <div 
        class="position-relative menu-item-container px-md-3 px-2"
        v-for="(item, index) in menu"
      >
        <div 
          class="menu-item position-relative"
          :class="[`bg-hover-${item.color ? item.color : 'primary'}`, {'active': isItemActive(item)}]"
        >
          <a 
            :href="linkUrl(item)" 
            :target="getTarget(item.url)"
            :rel="getRelNoFollow(item.url)"
            class="link-dark px-md-4 px-2"
            :id="`navbar-item-${index}`"
          >{{item.url.title}}</a>
          <div class="sub-menu position-absolute d-none p-4" :style="{'max-width': item.max_width}" v-if="item.menu">
            <div class="row">
              <div v-for="column in columns(item)" class="col">
                <div v-for="section, sectionIdx in column" :class="{'mb-3': sectionIdx < column.length - 1}">
                  <span v-if="section.title" class="section small" :class="`text-${item.color ? item.color : 'primary'}`">
                    {{section.title}}
                  </span>
                  <div v-for="subItem in section.menu">
                    <a
                      :href="subItem.url.url"
                      :target="getTarget(subItem.url)"
                      class="sub-item"
                      :rel="getRelNoFollow(subItem.url)"
                    >{{subItem.url.title}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[navbar-component] { 
  height: $navbar-height;

  .hamburger {
    font-size: 1.25rem;
    line-height: 1rem;
    z-index: 1;
    height: 100%;
    min-width: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    height: $navbar-height-mobile;

    .menu-item-container {
      flex: 1;
    }
  }

  .menu-item-container {
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        height: 1rem;
        width: 1px;
        top: 8px;
        right: 0;
        background-color: rgba($black, .75);
      }
    }
  }

  .menu-item {
    z-index: $zindex-menu-item;
    transition: all $animation-time-fast;
    height: $top-level-item-height;
    border-color: $dark;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;

    & > a {
      text-decoration: none;
    }

    &:hover,
    &.active {
      & > a {
        color: $white !important;
      }
    }

    &:hover .sub-menu {
      display: block !important;
    }

    & > a {
      height: 1.2rem;
    }
  }

  .sub-menu {
    top: $top-level-item-height;
    background-color: $white;
    cursor: default;
    color: $dark;
    z-index: 2;
    width: max-content;
    max-width: 40rem;

    a.sub-item {
      color: $dark;
      text-transform: capitalize;
      font-size: .75rem;
    }
  }
}
</style>


<template>
  <div class="filters-container">
    <div 
      v-if="theme==='modal'"
      id="categoryFilterModal"
      aria-labelledby="categoryFilterModal"
      aria-hidden="true"
      class="modal fade"
      tabindex="-1"
    >
      <div class="modal-dialog m-0">
        <div class="modal-content bg-gray-light">
          <div class="modal-body">
            <div class="close-icon p-3">
              <i class="ki ki-close fa-lg cursor-pointer" data-dismiss="modal"></i> 
            </div>
            <div class="container">
              <h3 class="text-center mx-2 py-3 py-md-5 h1">{{ ft("globals.filters.filters") }}</h3>
              <filters v-if="hasCategories" v-model="categories" @input="toggleFilter"></filters>
              <filters v-else :has-categories="false" v-model="filters" @input="toggleFilter"></filters>
            </div>
          </div>
          <div class="modal-footer container border-0 pb-5">
            <div class="row w-100 justify-content-center">
              <div class="col-12 col-lg-5 px-0 px-md-3 pb-3 pb-lg-0">
                <button type="button" class="w-100 btn btn-primary text-uppercase shadow-none" data-dismiss="modal">{{ ft('globals.advanced_search.show_results', {value: totalResults}) }}</button>
              </div>
              <div class="col-12 col-lg-5 px-0 px-md-3">
                <button  type="button" class="w-100 btn btn-dark text-uppercase shadow-none" @click="resetFilters">{{ ft('globals.advanced_search.reset') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="theme==='inline'">
      <filters v-if="hasCategories" v-model="categories" @input="toggleFilter"></filters>
      <filters v-else :has-categories="false" v-model="filters" @input="toggleFilter"></filters>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  props: {
    handler: {
      type: String,
      required: true
    },
    totalResults: {
      type: Number,
      default: 0
    },
    theme: {
      type: String,
      default: "modal"
    },
    hasCategories: {
      type: Boolean,
      default: true
    },
    orderBy: {
      type: String,
      default: "activated_at"
    },
    sortingOrder: {
      type: String,
      default: "desc"
    }
  },
  data: function() {
    return {
      categories: [],
      filters: [],
      filtersURL: ''
    }
  },
  mounted() {
    this.initFilters();
  },
  methods: {
    initFilters() {
      const params = new URLSearchParams(window.location.search);
      const filtersURL = params.get('page_content_filtering');
      this.filtersURL = !!filtersURL && params.get('page_content_name') == this.containerContent.name ? JSON.parse(filtersURL) : '';

      if (this.hasCategories) {
        this.categories = this.content.children.map( item => {
          const content = Fandom.getContent(item);
          
          const filters = content.children 
            ? content.children.map(this.initFilter)
            : [];

          return {
            title: content.title,
            filters
          }
        });
      } else {
        this.filters = this.content.children.map( item => this.initFilter(item) );
      }

      if (this.countActiveFilters > 0) {
        this.$emit('active-filters', this.countActiveFilters);
      }
    },
    initFilter(filter) {
      const filterContent = Fandom.getContent(filter);
      const isActive = this.filtersURL 
        ? this.filtersURL.flat().includes(filterContent.name)
        : false;

      return {content: {
        ...filterContent,
        isActive
      }};
    },
    toggleFilter() {
      this.$emit('active-filters', this.countActiveFilters);
      this.filter();
    },
    resetFilters() {
      const resetted = this.categories.map(category => {
        category.filters = category.filters.map(filter => {
          filter.content.isActive = false;
          return filter;
        })
        
        return category;
      });

      Vue.set(this, "categories", resetted);

      this.filter();
      this.$emit('active-filters', this.countActiveFilters);
    },
    filter() {
      Vue.nextTick(() => {
        Fandom.emit('grid-filter-apply', {
          contentFiltering: this.activeFilters,
          handlerContents: this.handler,
          orderBy: this.orderBy,
          sortingOrder: this.sortingOrder
        });
      })
    }
  },
  computed: {
    activeFilters() {
      return this.hasCategories
          ? this.categories.map( category => {
              return category.filters
                .filter( item => item.content.isActive )
                .flatMap( item => item.content.name );
            })
          : this.filters
            .filter( item => item.content.isActive )
            .flatMap( item => item.content.name );
    },
    countActiveFilters() {
      return this.activeFilters.flat().length;
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &-dialog {
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
  }

  &-content {
    height: 100vh;
    border: none;
    border-radius: 0;
  }

  &-body {
    overflow-y: scroll;

    .close-icon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

</style>
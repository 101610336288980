<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      

    };
  },
  methods: {
    rankingContent(item){
      return this.getContent(item.content);
    },
    getOwner(item){
      return this.pageModel.id_to_user[this.rankingContent(item).owned_by];
    },
    getImage(item){
      if (this.rankingContent(item) && this.rankingContent(item).thumbnail)
        return this.rankingContent(item).thumbnail.url.replace('/original/', '/thumb/');
    },
    getOwnerAvatarUrl(item){
      return get(this.getOwner(item), ["avatar", "url"], false);
    },
    getOwnerInitials(item){
      return `${this.getOwner(item).first_name?.[0] ?? ""}${this.getOwner(item).last_name?.[0] ?? ""}`;
    }
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" class="py-5" :class="contentType" :style="theme" list-ranking-component>
    <div class="container themed-item">
      <div class="row border-bottom border-secondary py-2 align-items-center" :class="{'border-top': index==0}" v-for="(item, index) in content.items" :key="`list-${item.$id}`">
        <div class="col-md-1">
          <p class="h2 text-lg-center mx-auto my-auto text-primary pb-1">#{{index+1}}</p>
        </div>
        <div id="img-wrapper" class="col-md-2">
          <div class="ar-16by9 h-100 bg-cover-center w-100 position-relative" :style="{ 'background-image': `url(${getImage(item)})`}">
            <div class="gradient d-md-none"></div>
            <div class="row flex-row-reverse d-md-none align-items-center ">
              <div v-if="getOwnerAvatarUrl(item)" class="col-auto mr-0 pr-4 pt-2 align-self-center justify-content-right"><img :src="getOwnerAvatarUrl(item)" class="size-sm avatar"></div>
              <div v-else class="col-auto mr-0 pr-4 pt-2 align-self-center justify-content-right">
                <span class="text-white avatar-text">{{getOwnerInitials(item)}}</span>
              </div>
              <div class="col-auto pl-5 pr-0 mx-0 align-self-center justify-content-right text-white"><span class="small px-0 mx-0 font-weight-bold" >{{(getOwner(item).username)}}</span></div>
            </div>
          </div>                
        </div>
        <div class="col-lg-6 col-md-5 align-self-start pt-2"> 
          <p class="h5 pt-2 pt-md-0">{{rankingContent(item).title}}</p>
          <p class="small line-clamp">{{rankingContent(item).description}}</p>
          <a :href="applyContextToUrl(rankingContent(item).slug)" class="link-primary small text-uppercase">{{ft("globals.view_more")}} <i class="fal fa-arrow-right"></i></a>
        </div>
        <div class="col-lg-3 col-md-4 align-items-center"> 
          <div class="row align-items-center"> 
            <div v-if="getOwnerAvatarUrl(item)" class="d-none d-md-block col-3 col-sm-2 col-md-3 align-items-end justify-content-center"><img :src="getOwnerAvatarUrl(item)" class="avatar size-sm"></div>
            <div v-else class="d-none d-md-block col-3 col-sm-2 col-md-3 align-items-end justify-content-center"><span class="text-white avatar-text">{{getOwnerInitials(item)}}</span></div>
            <div class="d-none d-md-block col-9 col-sm-10 col-md-9 pt-1 pb-1 align-items-center justify-content-center px-0" ><span class="small font-weight-bold pl-2 pl-xl-0" >{{(getOwner(item).username)}}</span></div>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .line-clamp{
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; 
    overflow: hidden;
  }

  .gradient {
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 25%;
    background-image: linear-gradient(0deg, rgba(238, 238, 238, 0), rgba(0, 0, 0, 0.3));
    position: absolute;
  }

  .avatar-text{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 50%;
    z-index: 1;
    background-color: $primary;
  }
</style>

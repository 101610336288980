<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from "src/modules/interactions_module.js";
import tileMixin from '../../modules/kenwood_swiss_tile_mixin.js';

export default {
  mixins: [fandomBasicMixin, interactionMixin, tileMixin],
  mounted() { },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getContentURL: Fandom.getContentURL,
  },
  computed: {
    author() {
      return (this.getContent(this.content.author)) ? this.getContent(this.content.author) : undefined;
    },
    share() {
      return this.content.interactions?.["share"] ?? false;
    },
    favourite() {
      return this.content.interactions?.["favourite"] ?? false;
    },
    owner() {
      if(Fandom.exists(this.content.owned_by) && this.pageModel.id_to_user[this.content.owned_by]) {
        return this.pageModel.id_to_user[this.content.owned_by];
      }
      return false;
    },
  }
}
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" page-article>
    <div class="container px-3 px-sm-0 py-4">
      <div class="row justify-content-center">
        <div v-if="decorator.title" class="col-12 py-2 col-lg-9 article-category font-xs text-uppercase">{{decorator.title}}</div>
        <div v-if="content.title" class="col-12 py-2 col-lg-9 article-description">
          <h1 class="h3">{{content.title}}</h1>
        </div>
        <div v-if="content.description" class="col-12 col-lg-9 py-2 article-description" v-html="content.description"></div>
        <div class="col-12 col-lg-9 py-3 d-flex justify-content-between">
          <div class="d-flex align-items-center" v-if="owner">
            <div class="owner-avatar border border-secondary bg-cover-center d-flex justify-content-center align-items-center" :style="avatarStyle">
              <span v-if="!ownerAvatarUrl" class="text-white">{{ownerInitials}}</span>
            </div>
            <div class="owner-name pl-3 d-flex flex-column align-items-center">
              <span>{{owner.first_name}} {{owner.last_name}}</span>
            </div>
        </div>
        <div class="share-interactions d-flex flex-column">
          <div class="d-flex align-items-center font-sm">{{ft("globals.recipe.share_recipe")}}:</div>
          <share-icons v-if="share" :content="content" :containerContent="containerContent"></share-icons>
        </div>
       </div>
     </div>
   </div>
   <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
   <stripe-related-articles
      :content="content"
      tagExtraField="related_content_article_tag"
      :optionalRelatedContentNames="content.related_content_articles"
    ></stripe-related-articles>
    <stripe-related-recipes
      v-if="!!content.related_content_recipe_tag || !!content.related_content_recipes"
      :content="content"
      :tagExtraField="!!content.related_content_recipe_tag ? 'related_content_recipe_tag' : 'course'"
      :optionalRelatedContentNames="content.related_content_recipes"
      :optionalViewMoreLink="(content.related_view_more_link || {}).url"
    ></stripe-related-recipes>
  </div>
</template>

<style lang="scss" scoped>
[page-article] {

  .owner-avatar {
    border-radius: 50%;
    width: 3.75rem;
    height: 3.75rem;
    background-color: $primary;

    @include media-breakpoint-down(sm) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .owner-name {
    font-weight: 600;
    
    @include media-breakpoint-down(sm) {
      font-size: .75rem;
    }
  }

  .author-container {
    min-width: 2px;
    .author-avatar {
      height: 42px;
      width: 42px;
      border-radius: 50%;
    }
  }
}


</style>
<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get, merge } from 'lodash';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      profilerContent: {},
      relatedContent: {},
      activeIndex: 0,
      efNameToUserSelectedItems: {},
      enterClass: 'slideInRight',
      leaveClass: 'slideOutLeft',
      init: false,
      visible: false
    }
  },
  mounted() {
    $('#modal-profiler').on('shown.bs.modal', () => {
      this.visible = true;
      if (!this.init) {
        this.initSteps();
      }
    })

    $('#modal-profiler').on('hidden.bs.modal', () => {
      this.activeIndex = 0;
      this.visible = false;
    })
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  methods: {
    initSteps() {
      Fandom.post({
        data: {main_content_name: this.containerContent.content.modal_profiler},
        url: "/api/v5/get_contents",
        success: (data) => {
          Vue.set(this, "profilerContent", data.name_to_content[this.containerContent.content.modal_profiler]);
          Vue.set(this, "relatedContent", data.name_to_content);
          const user = get(globalState.pageModel, ['user'], {});
          this.steps.forEach(step => {
            if (user[step.extra_field_name])
              Vue.set(this.efNameToUserSelectedItems, step.extra_field_name, user[step.extra_field_name]);
            else
              Vue.set(this.efNameToUserSelectedItems, step.extra_field_name, []);
          });
          this.init = true;
        }
      });
    },
    updateUserPreference() {
      const efName = this.activeStep.extra_field_name;
      Fandom.ajax({
        url: '/api/v5/profiler/user_profile',
        method: 'POST',
        data: {
          key: efName,
          value: this.efNameToUserSelectedItems[efName],
          min_length: this.activeStep.min_length
        },
        success: (data) => {
          Vue.set(this.pageModel, 'user', merge(this.pageModel.user, data));
          if (this.activeIndex < this.steps.length - 1) {
            this.activeIndex++;
          } else {
            this.close();
          }
        }
      })
    },
    toggleOption(option) {
      const efName = this.activeStep.extra_field_name;
      const index = this.efNameToUserSelectedItems[efName].indexOf(option);
      if (index > -1) {
        Vue.delete(this.efNameToUserSelectedItems[efName], index);
      } else {
        Vue.set(this.efNameToUserSelectedItems[efName], this.efNameToUserSelectedItems[efName].length, option);
      }
    },
    close() {
      Fandom.emit("profiler:update");
      clearAllBodyScrollLocks();
      $('#modal-profiler').modal("hide");
    },
    skip() {
      Fandom.setCookie(`kenwood-germany-hide-profiler-${globalState.pageModel.user.id}`, true);
      this.close();
    }
  },
  computed: {
    sizeClass() {
      return this.profilerContent.size || 'modal-full'
    },
    steps() {
      return this.profilerContent.steps || [];
    },
    activeStep() {
      return this.steps[this.activeIndex];
    },
    userCanProceed() {
      const efName = this.activeStep.extra_field_name;
      return efName in this.efNameToUserSelectedItems && this.efNameToUserSelectedItems[efName].length >= (this.activeStep.min_length || 1);
    },
    userCanRetreat() {
      return this.activeIndex > 0;
    },
    lastVisitedPage() {
      return Fandom.readCookie('last_visited_page') || '/';
    },
    activeOptions() {
      const efName = this.activeStep.extra_field_name;
      return this.efNameToUserSelectedItems[efName];
    },
  },
  watch: {
    activeIndex(newVal, oldVal) {
      if (!this.visible) {
        this.enterClass = '';
        this.leaveClass = '';
        return;
      } 
      
      if (newVal > oldVal) {
        this.enterClass = 'animated slideInRight';
        this.leaveClass = 'animated slideOutLeft';
      } else {
        this.enterClass = 'animated slideInLeft';
        this.leaveClass = 'animated slideOutRight';
      }
    }
  },
};
</script>

<template>
  <div 
    v-if="isContentVisibleToUser()"
    id="modal-profiler"
    data-keyboard=false
    data-backdrop="static"
    tabindex="-1"
    :class="[contentType]" class="modal fade bg-gray-300"
    modal-profiler-component
  >
    <div class="modal-dialog" :class="sizeClass">
      <div class="modal-content">
        <div class="modal-body">
          <div class="close-icon p-3">
            <i class="ki ki-close fa-2x cursor-pointer" @click="skip"></i> 
          </div>
          <div v-if="steps.length>0" class="container h-100 d-flex flex-column justify-content-between text-center pt-5">
            <div class="row">
              <div class="col-12 pb-4">
                <span class="text-uppercase">{{ ft('globals.step') }} {{ activeIndex + 1 }}/{{ steps.length }} </span>
              </div>
            </div>
            <transition :enter-active-class="`${enterClass} fast`" :leave-active-class="`${leaveClass} fast`" mode="out-in">
              <div class="row justify-content-center align-items-center options-container h-100" :key="`step-${activeIndex}`">
                <div class="col-12 col-md-10 col-lg-8">
                  <h2>{{activeStep.title}}</h2>
                  <div class="d-flex flex-wrap py-4" v-if="activeStep.options">
                    <component
                      v-for="option in activeStep.options"
                      :key="`option-${option}`"
                      :is="activeStep.size ? `option-${activeStep.size}` : 'option-small'"
                      :content="relatedContent[option]"
                      :active="activeOptions.indexOf(option) > -1"
                      @toggle="toggleOption(option)"
                    ></component>
                  </div>
                  <div class="w-100 py-4" v-else-if="activeStep.categories">
                    <div v-for="(category, categoryIndex) in activeStep.categories" :key="`category-${categoryIndex}`">
                      <div v-if="category.title" class="d-flex align-items-center justify-content-center">
                        <div class="border-bottom flex-grow-1"></div>
                        <div class="px-3 text-uppercase">{{ category.title }}</div>
                        <div class="border-bottom flex-grow-1"></div>
                      </div>
                      <div class="d-flex flex-wrap py-4" v-if="category.options">
                        <component
                          :is="activeStep.size ? `option-${activeStep.size}` : 'option-small'"
                          v-for="option in category.options"
                          :key="`option-${option}`"
                          :content="relatedContent[option]"
                          :active="activeOptions.indexOf(option) > -1"
                          @toggle="toggleOption(option)"
                        ></component>
                      </div>
                    </div>
                  </div>
                  <div v-if="activeStep.end">
                    <button class="btn btn-primary" @click="close">{{ ft('globals.close') }}</button>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div v-else class="d-flex w-100 h-100 align-items-center justify-content-center">
            <i class="fad fa-spinner fa-spin fa-3x text-primary fa-pulse"></i>
          </div>
        </div>
        <div class="modal-footer container border-0 pb-5" v-if="activeStep && !activeStep.end">
          <div class="row w-100 justify-content-center">
            <div class="col-12">
              <div class="d-flex justify-content-center py-4">
                <button class="btn btn-light mr-3 text-uppercase" :class="{disabled: !userCanRetreat}" :disabled="!userCanRetreat" @click="activeIndex--">{{ ft('globals.previous') }}</button>
                <button class="btn btn-primary text-uppercase" :class="{disabled: !userCanProceed}" :disabled="!userCanProceed" @click="updateUserPreference">{{ ft('globals.next') }}</button>
              </div>
              <div class="w-100 text-center">
                <span class="cursor-pointer" @click="skip">{{ ft('globals.skip') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[modal-profiler-component] {
  .modal {
    &-dialog {
      &.modal-full {
        position: fixed;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 100%;
        margin: 0;

        .modal-content {
          height: 100%;
          border: none;
          border-radius: 0;
          background-color: $gray-200;
        }
      }
    }

    &-body {
      overflow-y: scroll;

      .close-icon {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .border-bottom {
    border-color: $gray-500 !important;
  }

  .options-container {
    overflow-y: auto;
  }
}
</style>
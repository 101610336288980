import { get } from "lodash";

export default {
  methods: {
    mayOpenFavouriteModal(containerContent, interactionRecord, interaction) {
      if (Fandom.isUserRegistered()) {
        this.openFavouriteModal(containerContent, interactionRecord, interaction);
      } else {
        Fandom.emit("interaction-unauthorized", {type: "registration"});
      }
    }
  },
  computed: {
    category() {
      const decorator = Fandom.getDecorator(this.content, "label");
      return get(decorator, "title", false);
    },
    categoryStyle() {
      const decorator = Fandom.getDecorator(this.content, "label");
      return {
        backgroundColor: get(decorator, ["background_color"], "white"),
        color: get(decorator, ["color"], "black"),
      };
    },
    categoryTheme() {
      const decorator = Fandom.getDecorator(this.content, "label");
      return {
        "--category-background": get(decorator, ["background_color"], "white"),
        "--category-color": get(decorator, ["color"], "black"),
      };
    },
    owner() {
      if (Fandom.exists(this.content.owned_by) && this.pageModel.id_to_user[this.content.owned_by]) {
        return this.pageModel.id_to_user[this.content.owned_by];
      } else if (this.content.owned_by || this.content.layout.includes("recipe")) {
        return this.pageModel.anonymous_user;
      } else {
        return null;
      }
    },
    ownerInitials() {
      return `${get(this.owner, "first_name[0]", "")}${get(this.owner, "last_name[0]", "")}`;
    },
    ownerAvatarUrl() {
      return get(this.owner, ["avatar", "url"], false);;
    },
    avatarStyle() {
      return {
        'background-image': this.ownerAvatarUrl ? Fandom.getBackgroundUrl(this.ownerAvatarUrl).replace('/original/', '/thumb/') : ''
      }
    }
  }
}
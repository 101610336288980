<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";


export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
    };
  },
  methods: {
    getIcon(item){
      return item.icon.url;
    },
    getTitle(item){
      return item.title;
    },
    getDescription(item){
      return item.description;
    },
    getUrlObj(item){
      return item.url;
    },
    getLink(item){
      return this.getUrlObj(item).url;
    },
    getUrlTitle(item){
      return this.getUrlObj(item).title;
    }
  },
  computed: {
  }
};
</script>

<template>
<div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" class="py-5" :class="contentType" :style="theme" list-keypoint-component>
  <div :style="theme" class="container themed-item" >
    <div class="row justify-content-center m-2">
      <div class="col-md-4 w-100 my-5 px-5 " v-for="item in content.items" :key="`list-${item.$id}`" v-if="item">
        <div class="row allign-item-center mx-auto mb-4 keypoint-icon">
          <div class="ar-1by1 h-100 bg-cover-center w-100 rounded-circle avatar" :style="{ 'background-image': `url(${getIcon(item)})`}" ></div>           
        </div>
        <div class="row justify-content-center allign-item-center mt-5">
          <p class="col-md-12 h5 text-center">{{getTitle(item)}}</p>
        </div>
        <div class="row justify-content-center allign-item-center">
          <p class="col-md-12 small text-center">{{getDescription(item)}}</p>
        </div>
        <div class="row justify-content-center mb-2" v-if="getUrlObj(item)">
          <a class="col-md-12 link-primary small allign-baseline-end text-center" :href="getLink(item)" :target="getTarget(getUrlObj(item))" >{{getUrlTitle(item)}}<i class="fal fa-arrow-right"></i></a>
        </div>
      </div>          
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
[list-keypoint-component] {
  .keypoint-icon{
    width: 112px;
    height: 112px;
  }
}
</style>

import { render, staticRenderFns } from "./page-recipe-upload.vue?vue&type=template&id=be9bf978&scoped=true"
import script from "./page-recipe-upload.vue?vue&type=script&lang=js"
export * from "./page-recipe-upload.vue?vue&type=script&lang=js"
import style0 from "./page-recipe-upload.vue?vue&type=style&index=0&id=be9bf978&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be9bf978",
  null
  
)

export default component.exports
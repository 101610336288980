<script>
import { get } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { kenwoodSwissRelatedMixin } from "../../modules/kenwood_swiss_mixins_module.js";

export default {
  props: {
    tagExtraField: String,
    optionalRelatedContentNames: Array,
    optionalViewMoreLink: String,
  },
  mixins: [fandomBasicMixin, kenwoodSwissRelatedMixin],
  mounted() {
    if (this.optionalRelatedContentNames) {
      this.getRelatedContentsByNames(this.optionalRelatedContentNames, 'tile-recipe');
    } else {
      const tag = get(this, ["content", this.tagExtraField], this.content.decorators.default);
      this.getRelatedContents(tag, "tile-recipe");
    }
  },
  methods: {
    showAll() {
      location.href = this.optionalViewMoreLink || this.globalState.pageModel.name_to_content[this.tag].slug;
    }
  },
};

</script>

<template>
  <div class="bg-kenwood-light-grey" stripe-related-recipes>
    <div class="container" stripe-header>
      <div class="row justify-content-md-center pt-4">
        <div class="col-sm-8 text-center mt-4 py-2 mx-auto">
          <h4 class="title-composed">
            {{ft("globals.recipe.related_title")}}
          </h4>
        </div>
      </div>
    </div>
    <grid-isotope class="pb-3" :content="resultContent" v-if="resultContent"></grid-isotope>
  </div>
</template>

<style lang="scss">
[stripe-related-recipes] {

}

[grid-isotope-component] {
  .item--content {
    padding-bottom: 0% !important;
  }
}
</style>

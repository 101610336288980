import { get } from "lodash";


const multiLangMixin = {
  methods: {
    redirectTolang(lang) {
      return get(this, ["mainContentSlugs", lang], `/${lang}`);
    }
  },
  computed: {
    langs() {
      return get(globalState, ["pageModel", "langs"], []).filter(lang => lang !== this.currentLang);
    },
    currentLang() {
      return get(globalState, "lang", "de");
    },
    mainContentSlugs() {
      return get(globalState, ["pageModel", "name_to_content", globalState.pageModel.main_content_name, "canonical"], {});
    }
  }
};

const kenwoodSwissRelatedMixin = {
  data() {
    return {
      resultContent: null,
      tag: undefined,
    };
  },
  methods: {
    getRelatedContentsByNames(names, layout = "tile-recipe", filtering = []) {
      if (Fandom.exists(names)) {
        const getContentsParams = {
          include_rich_info: true,
          include_children: true,
          include_content_children: true,
          exclude_content_ids: [this.content.id],
          layout_extra_field_name: "$empty",
          layout: layout,
          limit: 3,
          contents_names: names,
          content_filtering: filtering
        };
        Fandom.getContentsByNames(this.successCallback, getContentsParams);
      }
    },
    getRelatedContents(tag, tileLayout = "tile-recipe", filtering = []) {
      if (Fandom.exists(tag)) {
        this.tag = tag;
        const getContentsParams = {
          include_rich_info: true,
          include_children: true,
          include_content_children: true,
          exclude_content_ids: [this.content.id],
          layout_extra_field_name: tileLayout,
          layout: tileLayout,
          content_filtering: filtering.concat([this.tag]),
          include_extra_field_content_filtering: true,
          limit: 3
        };
        Fandom.getSearchableContents(this.successCallback, getContentsParams);
      }
    },
    successCallback(data) {
      this.resultContent = data.name_to_content[data.main_content_name];
      this.resultContent.children.forEach((c, index) => {
        Vue.set(globalState.pageModel.name_to_content[c], "category", null);
      });
      Vue.set(this.resultContent, 'has_more_children', false);
      Vue.set(this.resultContent, 'column_count', 3);
      Vue.nextTick(() => Fandom.emit("dom-items-loaded"));
    }
  }
};


export {
  multiLangMixin,
  kenwoodSwissRelatedMixin,
};

<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { seoGrid } from "src/modules/seo_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  props: {
    items: Array,
    optAppendContents: Function
  },
  data() {
    return {
      getContentsParams: {
        limit: (this.content.limit || 8),
        content_filtering: []
      }
    }
  }, 
  mounted() {
    Fandom.onEvent("grid-filter-apply", this.handleGridFilterApply);
    seoGrid.initHistory();
  },
  methods: {
    handleGridFilterApply(data) {
      if (data.contentFiltering) this.getContentsParams.content_filtering = data.contentFiltering;
      if (data.orderBy) this.getContentsParams.order_by = data.orderBy;
      if (data.sortingOrder) this.getContentsParams.sorting_order = data.sortingOrder;
      Fandom.getContents(this.content.name, this.getContentsParams, () => {    
        this.getContentsCallback(false);
      }, false);
    },
    appendContents() {
      if (this.optAppendContents) {
        this.optAppendContents(this.content);
      } else {
        Fandom.appendContents(this.content.name, () => {
          this.getContentsCallback(true);
        }, null, this.getContentsParams);
      }
    },
    getContentsCallback(append) {
      seoGrid.updatePagination(this.content, this.getContentsParams.content_filtering, append);
      Fandom.emit('grid-fixed:filters', this.content.children_count);
    },
    paginationSEOUrl() {
      return seoGrid.paginationSEOUrl(this.content, this.getContentsParams.content_filtering);
    },
  },
  computed: {
    rowClass() {
      const columnCount = parseInt(get(this.content, 'column_count', 4));
      return [`row-cols-lg-${columnCount}`];
    },
    aspectRatioClass() {
      return this.content.aspect_ratio_class || '';
    },
    children() {
      return this.items || (this.content.children || []).map(c => this.getContent(c));
    },
    childrenCount() {
      return this.content.children_count;
    },
    contentStyle() {
      return {
        "background-color": this.content.background_color
      };
    },
  },
};

</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="[contentType, paddingY]" :style="[theme, contentStyle]" :id="anchorId" grid-fixed-component>
    <slot name="grid-header" :resultsCount="childrenCount"></slot>
    <div class="container">
      <div v-if="!children.length" class="row align-items-center justify-content-center">
        <div class="col-auto mt-5">
          <p>{{ft("globals.no_content")}}</p>
        </div>
      </div>
      <div v-else class="row row-cols-1 row-cols-md-2" :class="rowClass">
        <div v-for="(child, index) in children" class="col g-custom" :key="`child-${index}`">
          <div class="w-100 position-relative" :class="aspectRatioClass">
            <slot :child="child">
              <component :is="child.layout" :content="child" class="d-block w-100"></component>
            </slot>
          </div>
        </div>
      </div>
      <div class="row pt-5"  v-if="(content.enable_load_more == null || content.enable_load_more) && content.has_more_children">
        <div class="col-12 d-flex justify-content-center">
          <a class="btn text-uppercase" :href="paginationSEOUrl()" :class="themeButtonClass" @click.prevent="appendContents()">{{ft("globals.load_more")}}</a>
        </div>
      </div>
      <div class="row pt-5" v-if="(content.enable_load_more != null || !content.enable_load_more) && content.view_more_url">
        <div class="col-12 d-flex justify-content-center">
          <a class="btn text-uppercase" :href="content.view_more_url.url" :target="getTarget(content.view_more_url)" :class="themeButtonClass">{{content.view_more_url.title}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[grid-fixed-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
  
  .g-custom {
    padding: $fixed-grid-gap;

    @include media-breakpoint-down(sm) {
      padding: $fixed-grid-gap-mobile;
      padding-top: 0;
    }
  }
}
</style>
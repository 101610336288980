<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import tileMixin from '../../modules/kenwood_swiss_tile_mixin.js';
import { Youtube } from 'src/modules/youtube_module.js'
import { pick, get } from "lodash";
import "magnific-popup";
import "magnific-popup/dist/magnific-popup.css";

export default {
  mixins: [fandomBasicMixin, interactionMixin, tileMixin],
  mounted() {
    Vue.nextTick(() => {
      const procedure = $(this.$refs["procedure"]);
      if (procedure.length != 0) {
        procedure.magnificPopup({type: "image", delegate: ".magnific-popup"});
      }
    });
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getContentResponsiveImage: Fandom.getContentResponsiveImage,
    toggleYoutubePopup: Youtube.toggleYoutubePopup,

    isNumeric(quantity) {
      if ((quantity >0) || (quantity <20))
        return true
      else
        return false
    },
    getIngedients(field) {
      const recipeIngredients = field || [];

      return recipeIngredients.map(item => {
        const quantity = item.quantity || "";
        const title = item.ingredient
          ? this.getContent(item.ingredient).title
          : get(item, 'ingredient_str', '');

        return  {quantity, title};
      })
    },
    getIngredintsTitle(customTitle) {
      if(Fandom.exists(customTitle)) {
        return customTitle;
      } if(Fandom.exists(this.content.info.quantity) && this.content.info.quantity != undefined){
          if (this.isNumeric(this.content.info.quantity))
            return this.ft("globals.recipe.ingredients_for_portions")+" "+this.content.info.quantity+" "+(this.content.info.quantity > 1 ? this.ft("globals.recipe.people") :this.ft("globals.recipe.portion"));
          else
            return this.ft("globals.recipe.ingredients_for_portions")+" "+this.content.info.quantity.toLowerCase();
      } else return this.ft("globals.recipe.ingredients")
    },
    getTagsInfoFromContents(field, info = "title") {
      const fieldAr = field || [];
      const arrayConverter = content => Fandom.exists(content) ? pick(content, info) : false;
      const baseConverter = content => Fandom.exists(content) ? content[info] : false;
      const converter = Array.isArray(info) ? arrayConverter : baseConverter;
      return fieldAr.map(this.getContent).map(converter);
    },
    getTagsInfoFromCategoryClass(categoryClass) {
      const tagNameToInfo = get(this.pageModel, ['decorators', categoryClass], {});
      const contentDecorators = this.content.decorators[categoryClass] instanceof Array ? this.content.decorators[categoryClass] : [this.content.decorators[categoryClass]];
      return Object.values(tagNameToInfo).filter(tag => contentDecorators.includes(tag.name));
    }
  },
  computed: {
    color() {
      return get(this, ["content", "color"], "gray-primary");
    },
    backgroundColorClass() {
      return `bg-${this.color}`;
    },
    borderColorClass() {
      return `border-${this.color}`;
    },
    textColorClass() {
      return `text-${this.color}`;
    },
    favourite() {
      return (this.content.interactions && this.content.interactions["favourite"]) ? this.content.interactions["favourite"] : false;
    },
    share() {
      return (this.content.interactions && this.content.interactions["share"]) ? this.content.interactions["share"] : false;
    },
    like() {
      return (this.content.interactions && this.content.interactions["like"]) ? this.content.interactions["like"] : false ;
    },
    owner() {
      if(Fandom.exists(this.content.owned_by) && this.pageModel.id_to_user[this.content.owned_by]) {
        return this.pageModel.id_to_user[this.content.owned_by];
      }
      return false;
    },
    info() {
      return get(this, "content.info", false);
    },
    ingredientsBoxes() {
      const primaryBox = {
        ingredients: this.content.ingredients,
        allergens: this.content.allergens,
        title: this.content.ingredients_title
      };
      const secondaryBox = {
        ingredients: this.content.secondary_ingredients,
        allergens: this.content.secondary_allergens,
        title: this.content.secondary_ingredients_title
      };
      const additionalBoxes = get(this, ["content", "ingredient_boxes"], []);
      return [primaryBox, secondaryBox].concat(additionalBoxes).filter(box => Fandom.exists(box.ingredients));
    },
    accessories() {
      return [...this.getTagsInfoFromCategoryClass("product"), ...this.getTagsInfoFromCategoryClass("accessory")];
    },
    imageUrl() {
      let url = "";
      if (this.content.image) {
        url = this.getContentResponsiveImage("image");
      } else {
        const defaultCategories = this.getTagsInfoFromCategoryClass('default');
        url = defaultCategories.length > 0 ? this.getContentResponsiveImage("default_image", defaultCategories[0]) : "";
      }
      return url.replace("/original/", "/medium/");
    },
    altImage() {
      return get(this.content, ["image", "alt"], "");
    },
    ingredients_title_info(){
      return this.ft("globals.recipe.ingredients_box_title");
    },
    isYoutubePresent() {
      return get(this.content, ["play", "media_type"], false) === "youtube";
    },
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" :style="{ '--recipe-color': `var(--${color})` }" recipe >
    <div class="container pt-4 pb-3">
      <div class="row justify-content-center">
        <div class="recipe__main-image col-12 col-lg-8 col-xl-7 pl-lg-0">
          <div v-if="imageUrl" class="main-img">
            <img :src="imageUrl" :alt="altImage" class="recipe-main-image" :title="content.title" />
            <embed-youtube class="position-absolute-center" :content="content" :opt-container-content="containerContent" v-if="isYoutubePresent"></embed-youtube>
          </div>
          <div v-else class="recipe-main-image ar-16by9 bg-gray-light"></div>
          <recipe-time-box
            class="col-12 d-none d-md-block"
            v-if="content.time"
            :time="content.time"
          ></recipe-time-box>
        </div>
        <div class="col-12 d-md-none">
          <div class="border-bottom d-flex justify-content-between">
            <div class="recipe__info__favourite d-flex align-items-center" v-if="favourite">
              <i class="fa-bookmark pr-3" :class="[isFavourite(containerContent, favourite.interaction) ? `fas ${textColorClass}` : 'fal']" @click="mayOpenFavouriteModal(containerContent, favourite, favourite.interaction)"></i>
              <span class="text-uppercase small">{{ft("globals.cookbook.cookbook_add_btn")}}</span>
            </div>
            
            <div class="share-interactions d-flex align-items-center">
              <share-icons v-if="share" :content="content" :containerContent="containerContent"></share-icons>
            </div>
          </div>
        </div>
        <div class="recipe__info col-12 col-lg-4 col-xl-5 pt-0">
          <div class="row px-md-3">
            <div class="d-none recipe__info__favourite d-md-flex align-items-center col-12 py-3 pl-md-0 border-bottom" v-if="favourite">
              <i class="fa-bookmark pr-3 fa-1x" :class="[isFavourite(containerContent, favourite.interaction) ? `fas ${textColorClass}` : 'fal']" @click="mayOpenFavouriteModal(containerContent, favourite, favourite.interaction)"></i>
              <span class="text-uppercase small">{{ft("globals.cookbook.cookbook_add_btn")}}</span>
            </div>
            <div class="recipe__info__title col-12 py-4 pl-md-0">
              <h1 class="mb-0">{{content.title}}</h1>
            </div>
            <div class="recipe__info__owner pb-5 d-flex align-items-center pl-md-0 col-12" v-if="owner">
              <div class="owner-avatar border border-secondary bg-cover-center d-flex justify-content-center align-items-center" :style="avatarStyle">
                <span v-if="!ownerAvatarUrl" class="text-white">{{ownerInitials}}</span>
              </div>
              <div class="owner-name pl-2 d-flex flex-column align-items-center">
                <span>{{owner.first_name}} {{owner.last_name}}</span>
                <span v-if="owner.job_title">{{ owner.job_title }}</span>
              </div>
            </div>
            <div class="d-none col-12 share-interactions d-md-flex align-items-center pl-md-0 pb-4 border-bottom">
              <div class="d-flex align-items-center font-sm pr-3 text-uppercase small">{{ft("globals.recipe.share_title")}}</div>
              <share-icons v-if="share" :content="content" :containerContent="containerContent"></share-icons>
            </div>
            <recipe-time-box
              class="col-12 d-block d-md-none"
              v-if="content.time"
              :time="content.time"
            ></recipe-time-box>
          </div>
        </div>
        <div class="recipe__main-content align-self-start my-4 col-12 col-lg-8 col-xl-7 pl-lg-0">
          <div class="row">
            <div class="introduction col-12 py-4" v-if="content.description">
              <h2 class="h3 pb-2">{{ft("globals.recipe.introduction")}}</h2>
              <p class="pt-2 pb-0 mb-0" v-html="content.description"></p>
            </div>
            <accessories-carousel
              v-if="accessories.length > 0"
              :accessories="accessories"
            ></accessories-carousel>
            <div v-if="content.steps" class="procedure pt-4 col-12" ref="procedure">
              <h2 class="pb-3">{{ft("globals.recipe.instructions")}}</h2>
              <div v-for="(step, i) in content.steps" class="entire-step pb-4" :key="`step-${i}`">
                <div class="d-flex">
                  <div class="d-flex pr-md-4 pr-3">
                    <div class="step-counter text-white d-flex rounded-circle align-items-center justify-content-center" :class="backgroundColorClass">
                      {{i+1}}
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <div class="procedure-description" v-html="step.description"></div>
                    <!--a v-if="step.image" class="recipe-img bg-cover-center magnific-popup" :href="step.image.url" :style="{'background-image': getBackgroundUrl(step.image.url)}" ></a-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="recipe__ingredients col-12 col-lg-4 col-xl-5 mt-5 my-md-4 py-md-4">
          <h2 class="h3 text-dark">{{ingredients_title_info}}</h2>
          <ingredients-box
            v-for="(box, index) in ingredientsBoxes"
            :key="`ingredient-box-${index}`"
            :content="content"
            :opt-container-content="containerContent"
            :title="getIngredintsTitle(box.title)"
            :ingredients="getIngedients(box.ingredients)"
            :allergens="getTagsInfoFromContents(box.allergens)"
            :name="`ingredient-box-${index}`"
          ></ingredients-box>
        </div>
      </div>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
   
    <recipe-inherents :content="content" :opt-container-content="containerContent"></recipe-inherents>
  </div>
</template>

<style lang="scss">
[recipe] {
  --recipe-color: $red-cabbage;

  .recipe {
    &__main-image {
      .main-img {
        width: 100%;
        padding-bottom: 57.6%;
        position: relative;
        overflow: hidden;
      }

      .recipe-main-image {
        object-fit: cover;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__ingredients {
      @include media-breakpoint-up(lg) {
        position: sticky;
        top: calc(#{$navbar-height} + #{$header-height});
        z-index: 1019;
        height: fit-content;
      }

      @include media-breakpoint-down(sm) {
        order: 0;
      }
    }

    &__info {
      &__favourite {
        i {
          &:hover {
            color: var(--recipe-color);
            cursor: pointer;
          }
        }
      }

      &__owner {
        .owner-avatar {
          border-radius: 50%;
          width: 3.75rem;
          height: 3.75rem;
          background-color: $primary;

          @include media-breakpoint-down(sm) {
            width: 2.5rem;
            height: 2.5rem;
          }
        }

        .owner-name {
          font-weight: 600;
          
          @include media-breakpoint-down(sm) {
            font-size: .75rem;
          }
        }
      }
    }

    &__main-content {
      .step-counter {
        width: 53px;
        height: 53px;
      }

      .procedure-description {
        p {
          margin-bottom: 0 !important;
        }
      }

      .recipe-img {
        width: 100%;
        padding-bottom: 52.5%;
        transition: opacity 0.35s;
        &:hover {
          opacity: 0.92;
        }
      }

      .special-occasion__item {
        border-radius: 1rem;
        color: black;
      }

      .step-title {
        font-family: $bressay-font;
        font-size: 1.25rem;
      }

      @include media-breakpoint-down(sm) {
        order: 1;

        .step-counter {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
  }

  .recipe_quote {
    .h4 {
      font-size: 1.625rem !important;
    }

    .quote__description {
      font-family: "Bressay";
    }

    .quote__avatar {
      width: 6rem;
      height: 6rem;
    }
  }

  .btn-filter {
    color: $black;
    border-color: $black;
    font-size: .75rem;

    @include hover {
      color: $white !important;
      border-color: transparent;
      background-color: var(--recipe-color);
    }
  }

  .recipe__info-detail {
    &:last-child {
      padding-top: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    .recipe__info-detail {
      width: 50%;
      &:last-child {
        padding-top: 1rem;
      }
    }
  }

}
</style>

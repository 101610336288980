<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  computed: {
    url() {
      return get(this.content, ['url','url'], null);
    }
  }
}
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" tile-accessory-component>
    <a :href="url ? url : 'javascript: void(0)'" :target="getTarget(content.url)" class="tile deco-none cursor-pointer">
      <div class="aspect-1by1 d-flex flex-column bg-white">
        <div class="flex-grow-1 overflow-hidden">
          <div class="tile-image bg-cover-center h-100 w-100" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}"></div>
        </div>
        <div class="tile-title d-flex justify-content-center align-items-center text-center text-uppercase p-3">
          <p class="mb-0" :style="{'color': content.text_color}">
            {{content.title}}
          </p>
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-accessory-component] {
  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }

  .tile {
    &-title {
      height: 5rem;
    }

    &-image {
      transition: transform 0.75s linear;
    }

    &:hover {
      .tile-image {
        transform: scale(1.1);
      }
    }
  }

  .aspect-1by1 {
    aspect-ratio: 1/1;
  }
}
</style>

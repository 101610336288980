<script>
  import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
  import { Gigya } from 'src/modules/gigya_module';
  
  export default {
    mixins: [fandomBasicMixin],
    mounted() {
      Vue.nextTick(() => {
        const _gigya = new Gigya("DE");
        const configuration = _gigya.configuration(this.content.configuration, this.args);
        _gigya.execute(configuration);
      });
    },
    computed: {
      args() {
        const containerIdIndex = 2;
        const args = [];
        args[containerIdIndex] = this.containerId;
        return args;
      },
      containerId() {
        return `gigya-${this.content.configuration}`;
      }
    }
  };
  </script>
  
  <template>
    <div class="w-100 " user-gigya>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 my-5">
            <div :id="containerId"></div>
          </div>  
        </div>
      </div>
    </div>
  </template>
  
  <style lang="scss">
  [user-gigya] {
    background-color: whitesmoke;
  }
  </style>
  
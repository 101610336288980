<template>
  <div v-if="hasInfo" class="recipe-times p-md-0 pt-md-3">
    <div class="text-uppercase d-flex bg-gray-light py-3">
      <div v-if="preparationTime" class="recipe-times__preparation">
        <div class="py-2 d-flex flex-column align-items-center justify-content-center">
          <span>{{ ft("globals.recipe.preparation_time") }}</span>
          <span>{{ preparationTime }} {{ ft("globals.recipe.minutes") }}</span>
        </div>
      </div>
      <div v-if="showSeparator" class="border-right border-gray-primary"></div>
      <div v-if="cookingTime" class="recipe-times__cooking">
        <div class="py-2 d-flex flex-column align-items-center justify-content-center">
          <span>{{ ft("globals.recipe.cooking_time") }}</span>
          <span>{{ cookingTime }} {{ ft("globals.recipe.minutes") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [ fandomBasicMixin ],
  props: {
    time: {
      required: true,
      type: Object
    }
  },
  computed: {
    preparationTime() {
      return this.getDurationTime(this.time.preparation_time);
    },
    cookingTime() {
      return this.getDurationTime(this.time.cooking_time);
    },
    hasInfo() {
      return !!(this.preparationTime || this.cookingTime);
    },
    showSeparator() {
      return !!(this.preparationTime && this.cookingTime);
    }
  },
  methods: {
    getDurationTime(val) {
      if (typeof val === "number") {
        return val;
      } else if(typeof val === "string") {
        return parseInt(val.replace(/\D/g, ""));
      } else {
        return 0;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recipe-times {
  &__preparation, &__cooking {
    flex: 1;

    @include media-breakpoint-down(sm) {
      font-size: $small-font-size;
    }
  }
}
</style>
<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  data: function() {
    return { };
  },
  mounted() {
    this.content.accordion.forEach((item) => {
      Vue.set(item, "collapsed", true);
    })
  },
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  methods: {
    toggleItem(index) {
      Vue.set(this.content.accordion[index], "collapsed", !this.content.accordion[index]["collapsed"]);
    }
  },
  computed: { },
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    v-if="isContentVisibleToUser()" 
    :class="contentType"
    class="pb-4" 
    stripe-accordion-component
  >
    <div class="container themed-item" :style="theme">
      <h4 class="mb-5" v-if="content.title">{{content.title}}</h4>
      <div class="separator w-100 bg-primary"></div>
      <div v-for="(item, index) in content.accordion" :key="index" :ref="'accordion-'+index">
        <a 
          :aria-controls="`item-${index}-${content.$id}`"
          :href="`#item-${index}-${content.$id}`" 
          class="deco-none w-100" 
          data-toggle="collapse" 
          @click="toggleItem(index)">
          <div class="font-lg item-title d-flex my-4 align-items-center">
            <i class="fal text-primary mr-3" :class="item.collapsed ? 'fa-plus' : 'fa-minus'"></i>
            <span>{{item.title}}</span>
          </div>
        </a>
        <div class="collapse" :id="`item-${index}-${content.$id}`">
          <p class="mb-4" v-html="item.description"></p>
        </div>
        <div class="separator w-100 bg-primary"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-accordion-component] {
  .separator {
    height: 1px;
  }

  .font-lg {
    font-family: $bressay-font;
  } 
}
</style>

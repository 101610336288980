<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      paddingYDefault: 5
    };
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    mayToggleVideoPopup() {
      if (this.hasPlayInteraction) {
        Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
      }
    },
    playVideo() {
      if (this.hasVideo) {
        this.$refs["video"].muted = true;
        this.$refs["video"].play();
      }
    },
    pauseVideo() {
      if (this.hasVideo) {
        this.$refs["video"].pause();
      }
    }
  },
  computed: {
    isMobile() {
      return globalState.viewport.xs || globalState.viewport.sm;
    },
    hasMedia() {
      return Fandom.exists(this.content.image) || this.hasVideo;
    },
    hasVideo() {
      return Fandom.exists(this.content.video);
    },
    hasPlayInteraction() {
      return Fandom.exists(this.content.play);
    },
    height() {
      return get(this, ["content", "height"], "$4by3");
    },
    aspectRatioClasses() {
      if (this.hasVideo) {
        return this.height.indexOf("$") === 0 ? this.height.replace("$", "embed-responsive-") : "h-100 ar-sm-1by1";
      } else {
        return this.height.indexOf("$") === 0 ? this.height.replace("$", "ar-") : "h-100 ar-sm-1by1";
      }
    },
    containerStyle() {
      return {
        "background-image": this.propagateBackground ? this.backgroundImage : false
      };
    },
    rootClasses() {
      const paddingY = get(this, ["content", "padding_y"], 4);
      return [
        this.contentType,
        this.paddingY,
        this.propagateBackground ? "themed-item" : ""
      ];
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    },
    contentClasses() {
      const mobileDirection = this.content.reverse_mobile ? "flex-column-reverse" : "flex-column";
      const desktopDirection = this.content.reverse ? "flex-lg-row-reverse" : "flex-lg-row";
      return [
        mobileDirection,
        desktopDirection,
        this.propagateBackground ? "" : "themed-item"
      ];
    },
    contentStyle() {
      const height = this.height.indexOf("$") !== 0 ? this.height : null;
      return {
        "min-height": this.isMobile ? "auto" : height,
        "background-image": this.propagateBackground ? false : this.backgroundImage
      };
    },
    textColumnWidth() {
      const size = get(this, ["content", "text_column_width"], 6);
      return parseInt(size);
    },
    mediaColumnClasses() {
      const mediaClasses = this.hasMedia ? "" : "d-none d-lg-block";
      const playClasses = this.content.play ? "cursor-pointer" : "";
      return `col-lg-${12 - this.textColumnWidth} ${mediaClasses} ${playClasses}`;
    },
    textColumnClasses() {    
      return `col-lg-${this.textColumnWidth}`;
    },
    mediaImage() {
      return this.getContentResponsiveImageURL("image");
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    containerClass() {
      const propagateContainer = get(this, ["content", "propagate_container"], false);
      return propagateContainer ? "container-fluid" : "container";
    },
    getAuthor() {
      return this.content.author;
    },
  }
};
</script>

<template>
  <intersect @enter="playVideo" @leave="pauseVideo" v-if="isContentVisibleToUser()">
    <div v-easyadmin="easyadminId" :class="rootClasses" :style="theme" :id="anchorId" two-col-component>
      <div class="w-100 bg-cover-center" :style="containerStyle">
        <div class="position-relative overflow-hidden" :class="containerClass">
          <div class="row bg-cover-center" :class="contentClasses" :style="contentStyle">
            <div class="col-12 p-0 media-column d-inline-flex align-items-start" :class="mediaColumnClasses" @click="mayToggleVideoPopup">
              <slot name="stripe-two-col-custom-media-column">
                <div v-if="hasVideo" class="embed-responsive" :class="aspectRatioClasses">
                  <video loop webkit-playsinline playsinline ref="video">
                    <source :src="content.video.url" type="video/mp4">
                  </video>
                </div>
                <div
                  v-else-if="mediaImage"
                  class="bg-cover-center w-100 position-relative"
                  :title="content.image.alt"
                  :style="{'background-image': mediaImage}" :class="aspectRatioClasses"
                >
                  <div v-if="getAuthor" class="gradient"></div>
                </div>
                <div :class="aspectRatioClasses" class="w-100 position-absolute" v-if="hasPlayInteraction">
                  <div class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
                    <div class="icon-play-container d-flex align-items-center justify-content-center">
                      <i class="fal fa-play fa-2x ml-1"></i>
                    </div>
                  </div>
                </div>
              </slot>
              <div v-if="getAuthor" class="d-flex align-items-center author"> 
                <div><span class="small text-light font-weight-bold">{{getAuthor.username}}</span></div>
                <div><img :src="getAuthor.avatar.url" :alt="getAuthor.avatar.alt" class="avatar size-sm ml-3"/></div>
              </div> 
            </div>
            <div class="col-12 d-flex flex-column justify-content-center py-4 text-column" :class="textColumnClasses">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-10">
                  <text-section :content="content" :opt-container-content="containerContent"></text-section>
                  <slot name="stripe-two-col-custom-text"></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </intersect>
</template>

<style lang="scss" scoped>
.gradient {
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 25%;
  background-image: linear-gradient(0deg,hsla(0,0%,93.3%,0),rgba(0,0,0,.3));
  position: absolute;
}

/* Forced square rappresentation on mobile */
.author {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.icon-play-container {
  border: 1px solid $light;
  height: 145px;
  width: 145px;
  border-radius: 100%;;
  i { color: $light !important; }
}

@include media-breakpoint-down(md) {
  //.ar-16by9,
  //.ar-4by3,
  .ar-sm-1by1 {
    padding-bottom: 100%;
  }

  /* Forced no vertical padding on mobile */
  [two-col-component] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

/* IE11 hack */
@include ie {
  > div > .row {
    max-height: 1080px;
  }

  .media-column,
  .text-column {
    min-height: inherit;
    max-height: 1080px;
  }
}
</style>
